<template>
 <div v-if="isLoader" class="spin">
  <a-spin/>
 </div>
 <FlowCard v-else style="margin-bottom: 30px">
  <figure>
   <div v-if="data.type === 'SOLAR'" style="text-align: center">
    <img :src="require('../../../static/img/flows/solar.jpg')" alt=""
         style="max-height: 100px;max-width: 120px"/>
   </div>
   <div
    v-else
    class="pt-10"
    style="text-align: center;"
   >
    <div v-if="data.type === 'SHOP' && data.subType === 'BATTERYADVANCED'" style="height:100px;text-align: center">
     <svg enable-background="new 0 0 512 512" height="512px" style="height:100px" version="1.1"
          viewBox="0 0 512 512" width="400px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
<g>
<g>
<g>
<path clip-rule="evenodd"
      d="M336.949,83.675c0.35,0.002,0.697,0.002,1.048,0h1.048      c0.455,0.011,0.9,0.029,1.339,0.059c7.456,0.473,13.878,3.421,19.262,8.844c5.953,5.948,8.94,13.125,8.963,21.53v332.837      c-0.005,0.471-0.025,0.937-0.059,1.396c-0.321,7.806-3.287,14.518-8.904,20.134c-5.577,5.644-12.249,8.633-20.019,8.96      c-0.443,0.036-0.889,0.055-1.339,0.06H173.654c-0.412-0.005-0.818-0.023-1.222-0.06c-7.817-0.315-14.528-3.303-20.136-8.96      c-5.936-5.946-8.903-13.124-8.904-21.529V114.108c0.001-8.405,2.969-15.582,8.904-21.53c5.889-5.935,13.008-8.901,21.357-8.903      v0.059c86.639-0.03,140.374-0.05,161.201-0.059C335.554,83.68,336.251,83.68,336.949,83.675z M225.972,408.018v20.714h60.116      v-20.714H225.972z M206.011,297.808h39.514l-17.982,62.262c-0.359,1.248-0.282,2.471,0.233,3.667      c0.512,1.186,1.346,2.079,2.503,2.677c1.149,0.609,2.352,0.784,3.607,0.523c1.27-0.269,2.317-0.909,3.143-1.921l73.267-91.472      c0.869-1.116,1.237-2.378,1.107-3.782c-0.091-1.423-0.652-2.625-1.689-3.607c-1.057-0.993-2.298-1.498-3.724-1.514h-39.513      l17.981-62.202c0.358-1.289,0.281-2.548-0.232-3.782c-0.514-1.168-1.346-2.041-2.503-2.618      c-1.171-0.594-2.394-0.748-3.667-0.467c-1.291,0.269-2.356,0.909-3.2,1.921l-73.15,91.472c-0.907,1.096-1.315,2.337-1.224,3.724      c0.129,1.44,0.73,2.663,1.806,3.666C203.343,297.326,204.585,297.811,206.011,297.808z M266.358,132.496V112.77H245.7v19.727      h-19.729v20.714H245.7v19.728h20.658V153.21h19.729v-20.714H266.358z"
      fill="#333333" fill-rule="evenodd"/>
</g>
 <g>
<rect clip-rule="evenodd" fill="#333333" fill-rule="evenodd" height="32.702" width="106.613" x="202.984" y="33.808"/>
</g>
</g>
</g>
</svg>
    </div>
    <i v-if="data.type === 'ENERGY'" class="fa fa-bolt" style="font-size: 60px;"></i>
    <i v-if="data.type === 'ENERGY'" class="fas fa-burn" style="font-size: 60px;padding-left:5px;"></i>
    <i v-if="data.type === 'SVM'" class="fa fa-file" style="font-size: 60px;"></i>
    <i v-if="data.type === 'LEAD'" class="fa fa-calendar-check-o" style="font-size: 60px;"></i>
    <i v-if="data.type === 'SOLARSIMPLE'" class="fa fa-solar-panel" style="font-size: 60px;"></i>
    <i v-if="data.type === 'SHOP' && data.imageFileURL === null &&  data.subType !== 'BATTERYADVANCED'"
       class="fa fa-store" style="font-size: 60px;"></i>
    <div v-if="data.type === 'SHOP' && data.imageFileURL !== null &&  data.subType !== 'BATTERYADVANCED'"
         style="height:80px;text-align: center">
     <img :src="data.imageFileURL" alt=""
          style="max-height: 100px;max-width: 120px"/>
    </div>
    <div v-if="data.type === 'HYDROMX'" style="height:80px;text-align: center">
     <img alt="" src="https://www.hydromx.com/wp-content/uploads/2021/09/hydromx_logo.png"
          style="max-width: 200px"/>
    </div>
   </div>
  </figure>
  <figcaption>
   <sdHeading as="h6" style="text-align: center;font-size: 24px; padding-bottom: 40px">
    {{ data.displayName }}
   </sdHeading>
   <div>
    <a-row :gutter="20">
     <a-col v-if="data.signatureTypes.SIGNATURE" :xs="getColSize('SIGNATURE')">
      <sdButton class="btn-cart" size="small" style="width:100% !important;" type="primary"
                @click="handleSelectFlow('SIGNATURE', data)">
       <sdFeatherIcons class="mr-2" size="14" type="file-text"/>
       Buitendienst
      </sdButton>
     </a-col>
     <a-col v-if="data.signatureTypes.ESIGNATURE" :xs="getColSize('ESIGNATURE')">
      <sdButton class="btn-cart" size="small" style="width:100% !important;" type="primary"
                @click="handleSelectFlow('ESIGNATURE', data)">
       <sdFeatherIcons class="mr-2" size="14" type="mail"/>
       Binnendienst
      </sdButton>
     </a-col>
    </a-row>
   </div>
  </figcaption>
 </FlowCard>
</template>
<script>
import {FlowCard} from '../Style';
import PropTypes from 'vue-types';
import {computed, defineComponent} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';

const FlowCards = defineComponent({
 name: 'FlowCards',
 components: {FlowCard},
 props: {
  data: PropTypes.object,
 },
 methods: {
  handleSelectFlow(flow, type) {
   console.log(flow)
   console.log(type)
   this.$emit('set-flow', {config: type, flow: flow})
  },
  getColSize(type) {
   if (type === 'SIGNATURE') {
    if (this.data.signatureTypes.SIGNATURE && this.data.signatureTypes.ESIGNATURE) {
     return 12;
    }
    if (this.data.signatureTypes.SIGNATURE && !this.data.signatureTypes.ESIGNATURE) {
     return 24;
    }
   }
   if (type === 'ESIGNATURE') {
    if (this.data.signatureTypes.ESIGNATURE && this.data.signatureTypes.SIGNATURE) {
     return 12;
    }
    if (this.data.signatureTypes.ESIGNATURE && !this.data.signatureTypes.SIGNATURE) {
     return 24;
    }
   }
  }
 },
 setup() {
  const {dispatch, state} = useStore();
  const isLoader = computed(() => state.flows.loadingFlows);
  const {matched} = useRoute();

  return {
   dispatch,
   isLoader,
   matched,
  };
 },
});

export default FlowCards;
</script>
